<template>
  <div class="history text-center pa-4">
    <v-row justify="center" align="center">
      <!-- <v-img
        src="@/assets/designs/vision-left.png"
        contain
        max-width="20"
      ></v-img> -->
      <div class="text-h4 font-weight-bold mx-2">Vision of Sri Anjaneyam</div>
      <!-- <v-img
        src="@/assets/designs/vision-right.png"
        contain
        max-width="20"
      ></v-img> -->
    </v-row>

    <div class="ma-2">
      To nurture a world where individuals embrace a harmonious, happy, and
      contented life by understanding the essence of health, the purpose of
      life, and the path to inner peace. Through holistic healing rooted in
      Ayurveda, yoga, meditation, and sacred rituals, we aspire to transform
      lives and create a compassionate community free from suffering. Free from
      suffering both in physical and mental planes.
    </div>
    <div class="text-right">-Dr. M. Manogar, Cheif Doctor, BAMS</div>
  </div>
</template>

<script>
export default {
  name: "VisionOf",
};
</script>

<style>
.history {
  background-color: rgb(21, 136, 0, 0.2);
}
</style>
